import React from 'react'
import Link from 'gatsby-link'
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import panel from '../components/panel.module.css'
import styles from './blog.module.css'
import {Helmet} from 'react-helmet'

const BlogPage = () => (
    <div>
        <Header />
        <Helmet>
            <title>Blog</title>
        </Helmet>
        <div className={panel.panel}>
            <div className={panel.centeredText}>
                <h1 className={panel.h}>
                    Blog
                </h1>
            </div>
            <p>
                Hobbes: Everything familiar has disappeared! The world looks brand-new!<br />
                Calvin: A new year... a fresh, clean start!<br />
                Hobbes: It's like having a big white sheet of paper to draw on!<br />
                Calvin: A day full of possibilities!<br />
                <br />
                Calvin: It's a magical world, Hobbes, ol' buddy...<br />
                Calvin: ...let's go exploring!
            </p>
            <p>(...There's nothing on the blog yet.)</p>
        </div>
        <Footer />
    </div>
)

export default BlogPage